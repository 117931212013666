import React, { FunctionComponent } from 'react';
import FormularElement from './formularElement';
import ComponentBuilder from './componentBuilder';
import { ThirdPartySystemsConfigFb } from '../services/thirdPartySystemsService';

interface ElementParserProps {
  elements: Array<FormularElement>;
  keys?: Array<number>;
  formThirdPartySystemsConfig?: ThirdPartySystemsConfigFb;
}

const ElementParser: FunctionComponent<ElementParserProps> = ({
  elements,
  keys = [],
  formThirdPartySystemsConfig,
}) => (
  <>
    {elements.map((element, idx) => {
      const newKeys = [...keys, idx];
      return (
        <ComponentBuilder
          key={newKeys.join('.')}
          keys={newKeys}
          element={element}
          formThirdPartySystemsConfig={formThirdPartySystemsConfig}
        />
      );
    })}
  </>
);

export default ElementParser;
