import React, { FunctionComponent } from 'react';
import { ThirdPartySystemsConfigFb } from '../services/thirdPartySystemsService';

export type TSetAdditionalErrorMessage = (
  fieldName: string,
  message: string | null
) => void;

interface ConfigContextInterface {
  form?: Object;
  thirdPartySystems?: ThirdPartySystemsConfigFb;
}

const ConfigContext = React.createContext<ConfigContextInterface | null>({
  form: {},
});

interface ConfigContextProviderProps {
  form?: Object;
  thirdPartySystems?: ThirdPartySystemsConfigFb;
}

export const ConfigContextProvider: FunctionComponent<
  ConfigContextProviderProps
> = ({ form, thirdPartySystems, children }) => {
  return (
    <ConfigContext.Provider
      value={{
        form: form,
        thirdPartySystems: thirdPartySystems,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
