import FormularElement, {
  FormElement,
} from '../core/formBuilder/formularElement';
import { Location } from 'react-router-dom';

export const collectRoutes = (elements?: FormElement[]): string[] => {
  return (
    elements?.flatMap((element: FormularElement) => {
      const routes = element.route ? [element.route] : [];
      const nestedRoutes = collectRoutes(element.elements);
      return [...routes, ...nestedRoutes];
    }) || []
  );
};

export const isValidRoute = (
  project: string,
  location: Location,
  formRoutes: string[]
) => {
  if (!project) return false;

  const currentPath = location.pathname.replace(project, '');
  const cleanedPath = currentPath.replace(/\/+/g, '/');
  const finalPath = cleanedPath.startsWith('/')
    ? cleanedPath
    : `/${cleanedPath}`;
  return formRoutes.some((route) => finalPath === route);
};
